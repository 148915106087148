import React from 'react'
import './buttons.css'

export function StandardButton(props) {
    const style = {
        backgroundColor: (props.disabled)? 'gray' : props.color,
        display: (props.fit)? 'block' : 'inline-block',
        textAlign: (props.fit)? 'center' : 'auto',
    }

    const clickHandler = e => {
        if (!props.disabled && props.onClick) {
            props.onClick(e)
        }
    }

    return (
        <div
            className='button'
            {...props}
            style={style}
            onClick={clickHandler}
        >
            {props.children}
        </div>
    )
}

StandardButton.defaultProps = {
    color: 'gray',
    fit: true
}