import React, { useState, useEffect, useRef } from 'react';
import './inputs.css'

export function Input(props) {
    return (
        <input {...props} />
    )
}

export function Select(props) {
    return (
        <select {...props}>
            {
                Object.keys(props.options).map((key) => {
                    return <option value={key}>{props.options[key]}</option>
                })
            }
        </select>
    )
}

export function ScalaInput({value: propValue, left_text, middle_text, right_text, onChange, disabled}) {
    const [value, setValue] = useState(null)

    useEffect(() => {
        if (value !== null && onChange) {
            onChange(value)
        }
    }, [value, onChange])

    useEffect(() => {
        if (propValue !== undefined) {
            setValue(propValue)
        }
    }, [propValue])

    /**
     * Get the value based on touch position
     * @param {Event} event
     */
    const get = (event, configThumbWidth=15) => {
        const input = event.target;
        const touch = event.changedTouches[0];
        const min = parseFloat(input.getAttribute('min')) || 0;
        const max = parseFloat(input.getAttribute('max')) || 100;
        const delta = max - min;

        // Calculate percentage
        let percent;
        const clientRect = input.getBoundingClientRect();
        const thumbWidth = ((100 / clientRect.width) * (configThumbWidth / 2)) / 100;

        // Determine left percentage
        percent = (100 / clientRect.width) * (touch.clientX - clientRect.left);

        // Don't allow outside bounds
        if (percent < 0) {
            percent = 0;
        } else if (percent > 100) {
            percent = 100;
        }

        // Factor in the thumb offset
        if (percent < 50) {
            percent -= (100 - percent * 2) * thumbWidth;
        } else if (percent > 50) {
            percent += (percent - 50) * 2 * thumbWidth;
        }

        // Find the closest step to the mouse position
        return min + Math.round(delta * (percent / 100));
    }

    const handleTouchChange = e => {
        e.preventDefault()

        if (!e.target.disabled) {
            const newValue = get(e)
            if (newValue !== value) {
                setValue(get(e))
            }
        }
    }

    const extraClassName = (value === null) ? 'unset' : ''
    return (
        <div className='scala'>
            <input
                className={'scala-slider ' + extraClassName}
                type='range'
                min='-1000'
                max='1000'
                value={(value === null)? 0 : value}
                onChange={e => setValue(e.target.value)}
                onTouchStart={handleTouchChange}
                onTouchMove={handleTouchChange}
                onTouchEnd={handleTouchChange}
                disabled={disabled}
            />
            <div className='scala-pin left'/>
            <div className='scala-pin right'/>
            <div className='scala-pin middle'/>
            {left_text ? <div className='scala-text left'>{left_text}</div> : null}
            {right_text ? <div className='scala-text right'>{right_text}</div> : null}
            {middle_text ? <div className='scala-text middle'>{middle_text}</div> : null}
        </div>
    )
}