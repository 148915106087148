import React, { useState, useEffect, useCallback } from 'react';
import { Collapsible } from './collapsible'
import { Input, Select, ScalaInput } from './inputs';
import { StandardButton } from './buttons';
import { createAttribute, rateAttribute, unrateAttribute, deleteAttribute } from '../hooks/attribute';
import { useCurrentUser } from '../hooks/user';
import './attributes.css';
import { useCurrentTimestamp } from '../hooks/generic';
import { config } from '../config';
import { MessageBox } from '../chitalib/msgbox';

export function CreateAttributeCollapsible() {
    return (
        <Collapsible color='darkcyan' title='Add a new Attribute'>
            <CreateAttribute/>
        </Collapsible>
    )
}
export function CreateAttribute() {
    const me = useCurrentUser()
    const [attrName, setAttrName] = useState('')
    const [attrA, setAttrA] = useState('')
    const [attrB, setAttrB] = useState('')
    const [isSameB, setIsSameB] = useState(true);

    const create = e => {
        if (!me) return;
        console.log(`${attrName} ${isSameB} ${attrA} ${attrB}`)
        createAttribute(me, attrName, isSameB, attrA, attrB)
        .then(() => {
            setAttrName('')
            setAttrA('')
            setAttrB('')
            setIsSameB(true)
            console.log('succeed.')
        })
        .catch((error) => {
            alert(error.message)
        })
    }

    const handleAttrChange = (e) => {
        const value = e.target.value
        if (attrName === attrA) {
            setAttrA(value)
        }
        setAttrName(value)
    }

    useEffect(() => {
        setAttrB('')
    }, [isSameB])

    useEffect(() => {
        if (isSameB && (attrA !== attrB)) {
            setAttrB(attrA)
        }
    }, [attrA, attrB, isSameB])
    return (
        <div>
            <Input
                type='text'
                placeholder='Attribute Name'
                value={attrName}
                onChange={handleAttrChange}
            />
            <h3>Side A</h3>
            <Select
                options={{0: 'Too much'}}
                disabled
            />
            <Input
                type='text'
                placeholder='Side A'
                value={attrA}
                onChange={e => setAttrA(e.target.value)}
            />
            <h3>Side B</h3>
            <Select
                options={{true:'Not enough', false: 'Too much'}}
                value={isSameB}
                onChange={e => setIsSameB(e.target.value == 'true')}
            />
            <Input
                type='text'
                placeholder='Side B'
                value={attrB}
                onChange={e => setAttrB(e.target.value)}
                disabled={isSameB}
            />
            <br/><br/>
            <StandardButton onClick={create} color='darkcyan'>Add</StandardButton>
        </div>
    )
}

export function AttributeRatingSetter({attribute}) {
    const me = useCurrentUser()
    const timestamp = useCurrentTimestamp([attribute.name])
    const [value, setValue] = useState(null)

    const rate = (user, timestamp, attrName, value) => {
        rateAttribute(user, timestamp, attrName, value)
    }

    useEffect(() => {
        const changeTimer = setTimeout(() => {
            if (me && value !== null) {
                console.log('rating')
                console.log(value)
                rate(me, timestamp, attribute.name, value)
            }
        }, config.rating.ratingSubmittionDelay)
        return () => clearTimeout(changeTimer)
    }, [me, timestamp, attribute.name, value])

    const right_text = `Too Much ${attribute.A}`
    let left_text = `Not Enough ${attribute.B}`
    if (!attribute.is_same_b) {
        left_text = `Too Much ${attribute.B}`
    }

    const renderOptionButtons = () => {
        let buttons = []

        if (value !== null) {
            buttons.push(
                <StandardButton 
                    key='delete'
                    color='darkorange'
                    onClick={e => {
                        setValue(null)
                        unrateAttribute(me, timestamp, attribute.name)
                    }}
                >Remove Rating</StandardButton>,
            )
        }

        buttons.push(
            <StandardButton
                key='remove'
                color='darkred'
                onClick={e=>deleteAttribute(me, attribute.name)}
            >
                Remove Attribute
            </StandardButton>
        )
        buttons.push(
            <StandardButton 
                key='cancel'
                fit={true}
                color='gray'
            >
                Cancel
            </StandardButton>
        )
        
        return buttons
    }

    const handleScalaValueChange = useCallback(value => {
        setValue(value)
    }, [])

    return (
        <div className='attribute-rating'>
            <div className='attribute-rating-title'>{attribute.name}</div>
            <div className='attribute-rating-subtitle'>Rate yourself about <b>{attribute.name}</b></div>
            <ScalaInput
                left_text={left_text}
                right_text={right_text}
                middle_text='Perfect Balance'
                value={value}
                onChange={handleScalaValueChange}
            />
            <div className='attribute-rating-options'>
                <MessageBox
                    title={`${attribute.name} Options`}
                    description={`What would you like to do with the attribute ${attribute.name}?`}
                    buttons={renderOptionButtons()}
                >
                    ●●●
                </MessageBox>
            </div>
        </div>
    )
}