import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import './firebase/init';
import { HomePage } from './pages/home';
import { LoginPage } from './pages/login';
import { RatingPage } from './pages/rating';
import View from './chitalib/view';
import { StatisticsPage } from './pages/statistics';

function App() {
  const style = {
    position: 'fixed',
    height: '100%',
    width: '100%',
    left: 0,
    top: 0,
    overflow: 'auto',
  }

  return (
    <div style={style} className="app">
      <View style={{height: '100%'}}>
        <BrowserRouter>
          <Switch>
            <Route exact path='/login'>
              <LoginPage/>
            </Route>
            <Route exact path='/'>
              <HomePage/>
            </Route>
            <Route exact path='/fill'>
              <RatingPage/>
            </Route>
            <Route exact path='/statistics'>
              <StatisticsPage/>
            </Route>
            <Route path='*'>
              <Redirect to='/' />
            </Route>
          </Switch>
        </BrowserRouter>
      </View>
    </div>
  );
}

export default App;
