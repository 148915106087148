import React, { useState } from 'react';
import './msgbox.css'

export function MessageBox({title, description, children, buttons}) {
    const [shown, setShown] = useState(false)

    const onBackgroundClick = e => {
        setShown(false)
    }

    const onButtonClick = e => {
        setShown(false)
    }

    return (
        <>
            <span onClick={e => setShown(true)}>
                {children}
            </span>
            { shown? 
                <div className='chitalib-messagebox-wrapper' onClick={onBackgroundClick}>
                    <div className='chitalib-messagebox' onClick={e => e.stopPropagation()}>
                        <div className='chitalib-messagebox-title'>{title}</div>
                        <div className='chitalib-messagebox-desc'>{description}</div>
                        {
                            buttons.map((button) => {
                                return (
                                    <div className='chitalib-messagebox-button' onClick={onButtonClick}>
                                        {button}
                                    </div>
                                )
                            })
                        }
                    </div>
                </div>
            : null}
        </>
    )
}