import React from 'react';
import { UserRestrictedPage } from '../elements/pages';
import { useMyAttributes, useMyRatings } from '../hooks/attribute';
import './statistics.css'
import { Chart } from 'react-google-charts';
import { StandardButton } from '../elements/buttons'
import { Link } from 'react-router-dom'

export function StatisticsPage() {
    const attributes = useMyAttributes()

    if(null === attributes) {
        return null;
    }
    return (
        <UserRestrictedPage>
            <div className='statistics-page'>
                { attributes.length > 0 ? 
                    attributes.map((attribute) => {
                        return <AttributeStatistics attribute={attribute}/>
                    })
                : <p>There are no statistics to show you yet.</p>}
                <hr/>
                <div className='statistics-page-button'>
                    <Link to='/'>
                        <StandardButton fit={false} color='darkorange'>Done</StandardButton>
                    </Link>
                </div>
            </div>
        </UserRestrictedPage>
    )
}

function AttributeStatistics({attribute}) {
    const ratings = useMyRatings(attribute.name)
    
    if (ratings === null || ratings.length === 0) {
        return null
    }

    const formattedData = ratings.map((item) => [item.time, item.rating])
    console.log(formattedData)
    return (
        <div className='attribute-statistics'>
            <Chart
                width={'100%'}
                height={'100%'}
                chartType='LineChart'
                data={[
                    [
                        {type: 'date', label: 'date'},
                        {type: 'number', label: 'rating'},
                    ],
                ].concat(formattedData)}
                options={{
                    title: `Your past ratings of ${attribute.name}`,
                    hAxis: {
                        title: 'time',
                    },
                    vAxis: {
                        title: 'rating',
                        textPosition: 'none',
                    },
                }}
            />
        </div>
    )
}