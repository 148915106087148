import React, { useState, useEffect } from 'react'
import { UserRestrictedPage } from '../elements/pages'
import { useCurrentUser } from '../hooks/user'
import { Link } from 'react-router-dom'
import {StandardButton} from '../elements/buttons'
import { MyUserInfo } from '../elements/user'
import './home.css'

export function HomePage() {
    const me = useCurrentUser()
    const [value, setValue] = useState(null)
    const [valueUpdated, setValueUpdated] = useState(false)

    useEffect(() => {
        if (!value) return;

        const timer = setTimeout(() => {
            setValueUpdated(true)
        }, 3000)

        return () => {
            clearTimeout(timer)
        }
    }, [value])
    return (
        <UserRestrictedPage>
            <div className='home-page'>
                <MyUserInfo/>
                <Link to='/fill'>
                    <StandardButton fit={false} color='darkcyan'>Rate Yourself</StandardButton>
                </Link>
                <Link to='/statistics'>
                    <StandardButton fit={false} color='darkorange'>View Statistics</StandardButton>
                </Link>
            </div>
        </UserRestrictedPage>
    )
}