import React from 'react';
import { useCurrentUser } from '../hooks/user';
import './user.css'

export function MyUserInfo() {
    const me = useCurrentUser()
    return <UserInfo user={me}/>
}

export function UserInfo({user}) {
    return (
        <div className='user-info'>
            <UserImage user={user}/>
            <div className='user-info-title'>{user? user.displayName : null}</div>
        </div>
    )
}

function UserImage({user}) {
    let style = {}
    if (user) {
        style['backgroundImage'] = `url(${user.photoURL})`
    }

    return (
        <div className='user-image' style={style} />
    )
}