import React, { useState } from 'react'
import * as firebase from 'firebase'
import { GuestRestrictedPage } from '../elements/pages'
import { StandardButton } from '../elements/buttons'
import './login.css'

export function LoginPage() {
    return (
        <GuestRestrictedPage>
            <div className='login-page'>
                <div className='login-page-title'>Welcome to <b>Progress</b></div>
                <SignInWithGoogle />
            </div>
        </GuestRestrictedPage>
    )
}

function SignInWithGoogle() {
    const [loading, setLoading] = useState(false)

    const login = () => {
        setLoading(true)
        const provider = new firebase.auth.GoogleAuthProvider();
        firebase.auth().signInWithPopup(provider).then(function(result) {
            /* Great, we are signed in. */
        }).catch(function(error) {
            setLoading(false)
        });
    }

    return (
        <StandardButton
            onClick={login}
            color='#d50000'
            disabled={loading}
        >
            Sign in with Google
        </StandardButton>
    )
}