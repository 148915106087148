import React from 'react'
import { useMyAttributes } from '../hooks/attribute'
import { UserRestrictedPage } from '../elements/pages'
import { AttributeRatingSetter, CreateAttribute, CreateAttributeCollapsible } from '../elements/attributes'
import './rating.css'
import { StandardButton } from '../elements/buttons'
import { Link } from 'react-router-dom'
import { ScalaInput } from '../elements/inputs'

export function RatingPage() {
    const attributes = useMyAttributes()

    if (null === attributes) {
        return <>Loading...</>
    }
    else if (attributes.length === 0) {
        return <FirstTimeRatingPage />
    }
    else {
        return <RegularRatingPage attributes={attributes}/>
    }
}

function RegularRatingPage({attributes}) {
    return (
        <UserRestrictedPage>
            <div className='rating-page'>
                <div className='rating-page-title'>
                    <p>
                        <b>Let's go</b>, rate your self based on you feeling about yourself. You can place yourself wherever you want on each scale.
                    </p>
                    <p>
                        <i>rating everything is not mandatory.</i>
                    </p>
                </div>
                <hr/>
                { attributes ?
                    attributes.map((attribute) => {
                        return <AttributeRatingSetter key={attribute.name} attribute={attribute}/>
                    })
                : null}
                <hr/>
                <CreateAttributeCollapsible />
                <br/>
                <Link to='/'>
                    <StandardButton fit={false} color='darkorange'>Done</StandardButton>
                </Link>
            </div>
        </UserRestrictedPage>
    )
}

function FirstTimeRatingPage() {
    return (
        <UserRestrictedPage>
            <div className='rating-page'>
                <div className='rating-page-title'>
                    <p>
                        Let's set your first <b>Attribute</b>
                    </p>
                    <p>
                        <b>What is an Attribute? </b>
                        An Attribute is a parameter upon which you would like to rate yourself.
                        For each parameter you set, you will be able to scale yourself.
                    </p>
                    <p>
                        <b>Example Attribute: Dedication</b><br/>
                        Press on the scale to rate yourself between <u>Too many</u> to <u>Not enough</u>
                    </p>
                    <br/>
                    <ScalaInput
                        right_text={'Too many Dedication'}
                        left_text={'Not enough Dedication'}
                        middle_text={'Perfect Balance'}
                    />
                </div>
                <br/><hr/>
                <h2>Add new Attribute</h2>
                <CreateAttribute/>
            </div>
        </UserRestrictedPage>
    )
}