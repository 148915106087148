import { useState, useEffect } from "react";

export function useCurrentTimestamp(dependencies=[]) {
    const [timestamp, setTimestamp] = useState(Date.now())

    useEffect(() => {
        setTimestamp(Date.now())
    }, dependencies)

    return timestamp
}