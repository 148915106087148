import React, { useState } from 'react';
import './collapsible.css'

export function Collapsible(props) {
    const [collapsed, setCollapsed] = useState(props.collapsed)

    let contentStyle = {}
    let headerStyle = {
        backgroundColor: props.color
    }
    if (collapsed) {
        contentStyle = Object.assign({}, contentStyle, {
            height: 0,
            paddingTop: 0,
            paddingBottom: 0,
        })

        headerStyle = Object.assign({}, headerStyle, {
            borderRadius: '3px',
        })
    }

    const toggle = (e) => {
        setCollapsed(old => !old)
    }

    return (
        <div className='collapsible'>
            <div className='collapsible-header' style={headerStyle} onClick={toggle}>
                <span className='collapsible-header-title'>{props.title}</span>
                <span className='collapsible-header-symbol'>{collapsed ? '+' : '-'}</span>
            </div>
            <div className='collapsible-content' style={contentStyle}>
                {props.children}
            </div>
        </div>
    )
}

Collapsible.defaultProps = {
    collapsed: true,
    color: 'gray',
}