import { useState, useEffect } from 'react';
import * as firebase from 'firebase';
import { useCurrentUser } from './user';

export async function createAttribute(me, name, is_same_b, side_a, side_b) {
    return firebase.database()
    .ref(`/users/${me.uid}/attributes/${name}`)
    .set({
        created: Date.now(),
        is_same_b: is_same_b,
        A: side_a,
        B: side_b
    })
}

export async function deleteAttribute(user, name) {
    return firebase.database()
    .ref(`/users/${user.uid}/attributes/${name}/deleted`)
    .set(true)
}

export async function rateAttribute(user, timestamp, attrName, rating) {
    return firebase.database()
    .ref(`/users/${user.uid}/ratings/${attrName}/${timestamp}`)
    .set(rating)
}

export async function unrateAttribute(user, timestamp, attrName) {
    return firebase.database()
    .ref(`/users/${user.uid}/ratings/${attrName}/${timestamp}`)
    .remove()
}

export function useMyAttributes() {
    const me = useCurrentUser()
    const attributes = useAttributes(me)
    return attributes
}

function formatAttributesAsArray(dataDict) {
    return Object.keys(dataDict).map((key) => {
        let newAttr = Object.assign({}, {name: key}, dataDict[key])
        if (newAttr.deleted) {
            return null;
        }
        return newAttr
    })
}

export function useAttributes(user) {
    const [attrs, setAttrs] = useState(null)

    useEffect(() => {
        if (user) {
            firebase.database()
            .ref(`/users/${user.uid}/attributes/`)
            .on('value', (snapshot) => {
                const data = snapshot.val()
                if (data) {
                    setAttrs(formatAttributesAsArray(data))
                }
                else {
                    setAttrs([])
                }
            })
        }
    }, [user])

    return attrs
}

export function useMyRatings(attrName) {
    const me = useCurrentUser()
    return useRatings(me, attrName)
}

function formatRatingsAsArray(dataDict) {
    const array = Object.keys(dataDict).map((key) => {
        const obj = {
            time: new Date(parseInt(key)),
            rating: dataDict[key],
        }
        return obj
    })
    return array
}

export function useRatings(user, attrName) {
    const [ratings, setRatings] = useState(null)
    
    useEffect(() => {
        if (user) {
            firebase.database()
            .ref(`/users/${user.uid}/ratings/${attrName}`)
            .on('value', (snapshot) => {
                const data = snapshot.val()
                if (data) {
                    setRatings(formatRatingsAsArray(data))
                }
                else {
                    setRatings([])
                }
            })
        }
    }, [user, attrName])

    return ratings
}