import {useState} from 'react'
import * as firebase from 'firebase';

export function useCurrentUser() {
    const [user, setUser] = useState(null)
    firebase.auth().onAuthStateChanged((user) => {
        if (!user)
            user = false
        setUser(user)
    })

    return user
}