import * as firebase from 'firebase';

const firebaseConfig = {
    apiKey: "AIzaSyDmE3b_rROPutNW99GQ0EbxpPbDPHEP1iY",
    authDomain: "chitaprogress.firebaseapp.com",
    databaseURL: "https://chitaprogress.firebaseio.com",
    projectId: "chitaprogress",
    storageBucket: "chitaprogress.appspot.com",
    messagingSenderId: "42596770702",
    appId: "1:42596770702:web:ebfb2b46f5c7bca56dbadf",
    measurementId: "G-N34BBBXF9F"
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);
firebase.analytics();
firebase.auth().useDeviceLanguage()